export interface Repository {
	name: string;
	pushed_at: number;
	language?: string;
	archived?: boolean;
	html_url?: string;
	description?: string;
}

export interface Package {
	version?: string;
	keywords?: string[];
	type?: string;
}

export type Project = Repository & Package;

export type ProjectKeyword = 'library'|'framework'|'cli'|'platform'|'site'|'app'|'mobile'|'challenge';
export type ProjectLabel = 'Library'|'Framework'|'Command Line Tool'|'Web Platform'|'Website'|'Application'|'Mobile App'|'Interview Challenge'|'';

export const projectTypes: Record<ProjectKeyword, ProjectLabel> = {
	'library': 'Library',
	'framework': 'Framework',
	'cli': 'Command Line Tool',
	'platform': 'Web Platform',
	'site': 'Website',
	'app': 'Application',
	'mobile': 'Mobile App',
	'challenge': 'Interview Challenge'
};