import { Buffer } from 'buffer';
import { Project, ProjectLabel, projectTypes, Repository } from "./repositories.types";

const githubAccount = 'aeberdinelli';
const baseUrl = 'https://api.github.com';

async function request(url: string) {
	const response = await fetch(url, {
		headers: {
			Accept: 'application/vnd.github.v3+json'
		}
	});

	return await response.json();
}

function getProjectType(keywords: string[]): ProjectLabel {
	if (keywords) {
		for (let keyword of Object.keys(projectTypes)) {
			if (keywords.some(word => (word || '').toLowerCase() === keyword)) {
				return projectTypes[keyword];
			}
		}
	}

	return '';
}

export async function getRepositories(): Promise<Project[]> {
	let repos = await request(`${baseUrl}/users/${githubAccount}/repos`);

	repos = await Promise.all(
		repos.map(async (repo: Repository) => {
			const project: Project = { ...repo };

			if (repo.language) {
				const details = JSON.parse(await getPackageJson(repo.name, (repo.language || '').toLowerCase()));

				project.version = details.version || '';
				project.keywords = details.keywords || [];
				project.type = getProjectType(details.keywords);
			}

			return project;
		})
	);

	return repos;
}

/**
 * Returns the JSON as string from the package.json file of the repository
 * 
 * @param repo Name of the repo
 * @param language Language of the project
 * @returns {string} String with package.json contents
 */
export async function getPackageJson(repo: string, language: string = 'javascript'): Promise<string> {
	if (['javascript','typescript','php'].indexOf(language) === -1) {
		return '{}';
	}
	
	try {
		const file = (language === 'php') ? 'composer.json': 'package.json';
		const response = await request(`${baseUrl}/repos/${githubAccount}/${repo}/contents/${file}`);
		const pkg = Buffer.from(response.content, 'base64').toString();
	
		return pkg;
	}
	catch (err) {
		return '{}';
	}
}