interface SkeletonProps {
	width: number|string,
	height?: number|string
};

export function Skeleton(props: SkeletonProps) {
	const styles: any = {
		width: props.width,
		height: props.height || '1em'
	};

	return (
		<span className="skeleton" style={styles}></span>
	);
}