import { Page } from '../../components/Page';
import { Role } from '../../components/Role';
import { Job } from '../../components/Job';

import './experience.css';

type ExperienceStack = Record<string, string[]>;

export function Experience() {
	const Stack: ExperienceStack = require('./stacks.json');

	return (
		<Page title="Alan's Experience">
			<h1>Experience</h1>
			
			<div className="experience">
				<Job company="Santex" withTitles>
					<Role time="1 year" company="RBI" position="Backend developer" stack={Stack.Santex}>
						<p>
							Santex is a software factory that offers services to several international companies.
						</p>
						<p>
							I'm currently working for a huge multi-national restaurant brands company. Providing fixes and helping separating and expanding their business from US to Europe.
						</p>
					</Role>
				</Job>
				<Job company="Incluit">
					<Role time="1 year 3 months" company="Naranja" position="Backend developer" stack={Stack.Naranja}>
						<p>Naranja is a fintech company with a strong focus on online services and user experience. </p>
						<p>
							I worked on the backend implementing features and designing the infrastructure for some of them. <br />
							I also worked on two of the main libraries they use and wrote one completely from scratch!
						</p>
					</Role>
					<Role time="1 year" company="Telecom, Personal" position="Full stack developer" stack={Stack.Personal}>
						<p>Personal is one of the biggest mobile phone network company in Argentina. </p>
						<p>
							I worked on their web platform both in UI and backend implementing features and 
							tracking, fixing down any issues could've come up in production. 
						</p>
					</Role>
				</Job>
				<Job company="Globant">
					<Role time="1 year 7 months" company="Walt Disney" position="Tech Lead" stack={Stack.DisneyLead}>
						<p>
							I was in charge of the Failed Booking and Fraud Prevention team. Reporting directly to the DM at Disney. 
						</p>
						<p>
							We worked with big data and analisys with tools like Splunk, to find cases where the user couldn't complete the purchase and fix it.
						</p>
					</Role>
					<Role time="1 year" company="Walt Disney" position="Frontend developer" stack={Stack.Disney}>
						<p>I worked at Globant for Walt Disney Parks and Resorts Online.</p>
						<p>
							I created fixes and new features for the web platform integrating a huge stack, 
							Rest Services and SOA Architecture with a strong focus on security, resiliency, 
							performance and scalability.
						</p>
					</Role>
				</Job>
				<Job company="Startup">
					<Role time="3 months" company="Jointter" position="Full stack developer" stack={Stack.Jointter}>
						<p>
							I was one of the two developers in this startup with thir ecommerce platform product. <br />
							Job lasted only a few months because the startup was disolved.
						</p>
					</Role>
				</Job>
			</div>

			<p>
				<small>
					You can visit my 
						<a href="https://www.linkedin.com/in/aeberdinelli/" rel="noreferrer" className="external" target="_blank">linkedin</a> 
					for more details.
				</small>
			</p>
		</Page>
	);
}
