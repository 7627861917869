import { useState } from 'react';

export type TreeItem = {
	label: string,
	visible?: boolean,
	childs?: any[]
};

export interface TreeProps {
	items?: TreeItem[];
	children?: any
};

export interface ChildTreeProps {
	label: string;
	items?: TreeItem[];
	visible?: boolean;
	children?: any;
};

export function ChildTree(props: ChildTreeProps) {
	const [visible] = useState(true);

	if (props.items!.length === 0) {
		return null;
	}

	const classList = `child-tree-component child-tree ${(visible) ? 'visible' : 'hidden'}`;

	const childs: any = props.items?.map((item: TreeItem, key: number) => {
		if (item.childs) {
			return (
				<ChildTree key={key} label={item.label} items={item.childs} />
			);
		}

		return (
			<li key={key}>
				<span className="stack-item">{item.label || item as any}</span>
			</li>
		);
	});

	return (
		<li className="child-title">
			<div>{props.label}</div>
			<ul className={classList}>{childs}</ul>
		</li>
	);
}

export function Tree(props: TreeProps) {
	function renderChilds() {
		if (props.children) {
			return props.children;
		}

		return props.items!.map((item: TreeItem, key: number) => {
			if (!item.childs || item.childs.length === 0) {
				return (
					<li key={key}>
						<span className="stack-item">{item.label as any}</span>
					</li>
				);
			}

			return (
				<ChildTree key={key} label={item.label} items={item.childs} />
			);
		});
	}

	return (
		<ul className="tree tree-component">
			{renderChilds()}
		</ul>
	);
}