import { useEffect, useState } from 'react';

import { Project as ProjectType } from '../../services/repositories.types';
import { Project } from '../../components/Project';
import { Page } from '../../components/Page';

import { projectTypes } from '../../services/repositories.types';
import { getRepositories } from '../../services/repositories';

import './projects.css';

export type ProjectsProps = {};

export function Projects(props: ProjectsProps) {
	const [ isLoading, setIsLoading ] = useState<boolean>(true);
	const [ projects, setProjects ] = useState<ProjectType[]>([]);

	useEffect(() => {
		(async () => {
			const repos = await getRepositories();

			setProjects(repos.sort((a, b) => (a.pushed_at - b.pushed_at)));
			setIsLoading(false);
		})();

		return () => {};
	}, [props]);

	function renderProjects(type: string = '') {
		if (isLoading) {
			return (
				<section>
					{new Array(2).fill('').map((_, key) => (<Project key={key} isLoading={true} />))}
				</section>
			);
		}

		if (type) {
			const projectsByType = projects
				.filter((project: any) => project.type && project.type.toLowerCase() === type.toLowerCase());

			// Do not render category if there are no projects there
			if (projectsByType.length === 0) {
				return null;
			}

			return (
				projectsByType
					.map((project: ProjectType, key: number) => (
						<Project
							key={`${type}-${key}`}
							version={project.version || ''}
							deprecated={project.archived}
							stack={[project.language || 'Documentation']}
							url={project.html_url}
							name={project.name}
							description={project.description}
						/>
					))
			);
		}

		return projects.map((project: any, key: number) => (
			<Project
				key={key}
				version={project.version || ''}
				deprecated={project.archived}
				stack={[project.language || 'Documentation']}
				url={project.html_url}
				name={project.name}
				description={project.description}
			/>
		));
	}
	
	function renderCategories() {
		const categories: any = [];

		Object.values(projectTypes).forEach((category: string, key: number) => {
			const projectsBodies = renderProjects(category);

			if (!projectsBodies) {
				return;
			}

			categories.push(
				<div className="project-type" key={`category-${key}`}>
					<h1>{category}</h1>
					<div className="project-type-content">
						{projectsBodies}
					</div>
				</div>
			);
		});

		return categories;
	}

	return (
		<Page title="Alan's Projects">
			{renderCategories()}
		</Page>
	);
}