import { Skeleton } from '../../components/Skeleton';

interface ProjectProps {
	name?: string;
	deprecated?: boolean;
	url?: string;
	stack?: string[];
	description?: string;
	isLoading?: boolean;
	version?: string;
}

export function Project(props: ProjectProps) {
	if (props.isLoading) {
		return (
			<div className="project-component project-loading">
				<Skeleton width="40%" />
				<Skeleton width="80%" />
				<Skeleton width="30%" />
			</div>
		);
	}

	return (
		<div className={`project project-component ${(props.deprecated === true) ? 'project-deprecated' : ''}`}>
			<div className="project-name">
				<a href={props.url} target="_blank" rel="noreferrer">
					{props.name}
				</a>
				<span className="project-version">{props.version || ''}</span>
			</div>
			<div className="project-description">
				{props.description}
			</div>
			<div className="project-stack">
				{(props.stack || ['Plain Text'])
					.filter(tech => Boolean(tech))
					.map((tech, key) => (<div className="stack-item" key={key}>{tech}</div>))
				}
			</div>
		</div>
	);
}