import { Page } from '../../components/Page';
import { ContactLink } from '../../components/ContactLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faSpotify, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

export function Contact() {
	function openEmailWindow(event) {
		event.preventDefault();
		const { n1, n2, n3, n4 } = event.target.dataset;

		window.location.href = `mailto:${atob(n1)}${atob(n2)}@${atob(n3)}.${atob(n4)}`;
	}

	return (
		<Page title="Contact Alan">
			<h1>Contact me</h1>
			<div className="contact-link">
				<p>
					<small>
						The simple and old-school way, just send me an email and I will get back to you shortly.
					</small>
				</p>
				<div className="text-center">
					<a 
						className="email-link"
						href="https://alanberdinelli.link/" 
						id="email" 
						data-n1="YWU=" 
						data-n2="YmVyZGluZWxsaQ==" 
						data-n3="Z21haWw=" 
						data-n4="Y29t"
						onClick={(event) => openEmailWindow(event)}
					>
						<FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
						Send me an email
					</a>
				</div>
			</div>
			<br />
			<h1>Find me on social media</h1>
			<p>
				<small>You can also interact with me here</small>
			</p>
			<ContactLink 
				href="https://linkedin.com/in/aeberdinelli" 
				label="Hire me"
				icon={faLinkedin} 
			/>
			<ContactLink
				href="https://instagram.com/aeberdinelli2"
				label="See my photos"
				icon={faInstagram}
			/>
			<ContactLink
				href="https://github.com/aeberdinelli"
				label="Read my code"
				icon={faGithub}
			/>
			<ContactLink
				href="https://open.spotify.com/user/f2a3f8d34cpssz8vjrw4913vc?si=MA_ddB1uSvyV3CJgNIwmrQ"
				label="Listen to my playlists"
				icon={faSpotify}
			/>
			<ContactLink
				href="https://www.facebook.com/aeberdinelli"
				label="Learn more about me"
				icon={faFacebook}
			/>
		</Page>
	);
}