interface JobProps {
	company: string;
	time?: string;
	withTitles?: boolean;
	children?: any
};

export function Job(props: JobProps) {
	return (
		<div className="job">
			<div className="job-company hidden-sm">
				<div>{props.company}</div>
			</div>
			<div className="job-body">
				{(props.withTitles === true) ? (
					<div className="role hidden-sm">
						<h2 className="text-center">Role details</h2>
						<h2 className="text-center">Tech Stack</h2>
					</div>
				) : ''}
				{props.children}
			</div>
		</div>
	)
}