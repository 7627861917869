import { useEffect } from "react";
import { Nav } from '../Nav';

interface PageProps {
	className?: string;
	title: string;
	children?: any;
};

export function Page(props: PageProps) {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return (
        <div className={`page page-component ${props.className || ''}`}>
			<div className="page-header">
				<Nav />
			</div>
			<div className="page-body">
            	{props.children}
			</div>
        </div>
    );
}