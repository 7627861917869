import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LinkProps {
	icon: any;
	href: string;
	label?: string;
	children?: any;
};

export function ContactLink(props: LinkProps) {
	return (
		<div className="contact-link">
			<a href={props.href} target="_blank" rel="noreferrer">
				<FontAwesomeIcon icon={props.icon} style={{ marginRight: '7px' }} />
				{props.label || props.children}
			</a>
		</div>
	);
}