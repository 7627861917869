import { Link } from 'react-router-dom';

import './nav.css';

export function Nav() {
	return (
		<nav className="nav top-nav">
			<Link to="/">About<span className="hidden-sm"> Me</span></Link>
			<Link to="/experience">Experience</Link>
			<Link to="/projects">Projects</Link>
			<Link to="/contact">Contact<span className="hidden-sm"> Me</span></Link>
		</nav>
	);
}