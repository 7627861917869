interface RoleProps {
	company: string;
	position: string;
	time?: string;
	current?: boolean;
	stack?: string[];
	description?: string;
	children?: any;
}

export function Role(props: RoleProps) {
	function renderStack() {
		if (!props.stack || props.stack.length === 0) {
			return '&nbsp;';
		}

		return props.stack.map((tech: string, key: number) => (
			<div className="stack-item" key={key}>{tech}</div>
		));
	}

	return (
		<div className="role-component role">
			<div className="job-details">
				<div className={`job-detail client-name ${(props.current) ? 'current' : ''}`}>{props.company}</div>
				<div className="job-detail role-name">{props.position}</div>
				<div className="job-detail role-time">{props.time || null}</div>
				<div className="job-detail client-details">
					{props.description || props.children}
				</div>
			</div>
			<div className="job-stack">
				{renderStack()}
			</div>
		</div>
	);
}