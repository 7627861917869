import { Page } from '../../components/Page';
import { Tree, TreeItem } from '../../components/Tree';

export function Home() {
	const knowledge: any = require('./knowledge.json');

	function renderKnowledge() {
		const items: TreeItem[] = Object.keys(knowledge).map(label => {
			const item: TreeItem = { 
				label,
				childs: knowledge[label]
			};

			return item;
		});

		return (
			<Tree items={items} />
		);
	}

	return (
		<Page title="Alan Emanuel Berdinelli">
			<section id="home" className="home-section">
				<h1>About me</h1>
				<div className="intro">
					<div className="picture">
						<img src="/img/avatar.jfif" width="190" height="190" alt="" />
					</div>
					<div className="text">
						<p>
							Hi, I'm Alan. I am 28 years old. I program since I was around 14. I can create from simple websites to complete full desktop or mobile applications all by myself. 
							That includes, of course, backend and frontend development as well as any devops things involved <span className="emoji" title="Nerd face">🤓</span>.
						</p>
						<p>
							That doesn't mean I prefer to work that way only. I love having co-workers, sharing some <a className="external" href="https://en.wikipedia.org/wiki/Mate_(drink)" rel="noreferrer" target="_blank">mate</a> and learning from them as well as teaching my recent discovered library or framework to them. 
							I worked on teams for years, most of the time with <strong>scrum</strong> methodologies, and a few other times with kanban.
						</p>
						<p>
							I have a strong focus on how an app should work and perform. I don't like websites that take more than 3s to load or applications that act randomly.
						</p>
					</div>
				</div>
			</section>
			<section id="knowledge" className="home-section">
				<h1>Knowledge</h1>
				{renderKnowledge()}
			</section>
		</Page>
	);
}
